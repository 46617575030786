import { makeStyles } from "@material-ui/styles";

import { colors, Commons } from "../../styleguide";

const styles = makeStyles({
  container: {
    textAlign: "center",
    overflow: "auto",
  },
  content: {
    backgroundColor: "#282c34",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
  },
  containerLogo: {
    backgroundColor: "black",
    border: `12px solid ${colors.themeColor}`,
    borderRadius: "50%",
    overflow: "hidden",
    width: "30vw",
    height: "30vw",
    zIndex: 0,
    marginTop: 30,
    maxWidth: 300,
    maxHeight: 300,
    "@media(max-width: 780px)": {
      borderWidth: 6,
      width: "42vw",
      height: "42vw",
    },
  },
  logo: {
    maxHeight: 300,
    height: "30vw",
    pointerEvents: "none",
    animation: "$App-logo-spin infinite 6s linear",
    marginBottom: 30,
    "@media(max-width: 780px)": {
      height: "42vw",
    },
  },
  description: {
    fontSize: 36,
    margin: "30px 30px 42px 30px",
    maxWidth: 600,
    "@media(max-width: 780px)": {
      fontSize: 18,
    },
  },
  link: {
    backgroundColor: colors.themeColor,
    color: "white",
    borderRadius: 12,
    padding: "12px 24px",
    textDecoration: "none",
    fontWeight: "600",
    marginBottom: 18,
    width: '100%',
    maxWidth: 600,
  },
  socialLabel: {
    color: colors.white,
    fontSize: 24
  },
  row: {
    ...Commons.flexRow,
    width: '100%',
    maxWidth: 600,
    marginTop: 30, 
    marginBottom: 90
  },
  linkIcon: {
    textDecoration: 'none',
    flex: 1,
    textAlign: 'center'
  },
  icon: {
    width: 72,
    height: 'auto'
  },
  "@keyframes App-logo-spin": {
    "0%": {
      transform: "scale(1) rotate(0deg)",
    },
    "25%": {
      transform: "scale(1.1) rotate(15deg)",
    },
    "50%": {
      transform: "scale(1.2) rotate(0deg)",
    },
    "75%": {
      transform: "scale(1.1) rotate(-15deg)",
    },
    "100%": {
      transform: "scale(1) rotate(0deg)",
    },
  },
});

export default styles;
